<template>
  <footer class="footer pad--3 flex flex--center width--100 mar--top__2">
    <div class="footer--contet flex flex--space__hor flex--start__vert">
      <div
        class="flex flex--column"
        v-if="$route.params.organizationID !== '5ebe682fb308810011922d13'"
      >
        <div class="font--s16">Powered by</div>
        <a href="https://okourse.com/" target="_blank">
          <img src="../../assets/SVG3.svg" class="height--3" />
        </a>
        <div class="font--s16 color--grey">© 2020 — All Rights Reserved</div>
      </div>
      <div class="flex">
        <div class="flex flex--column">
          <div
            class="font--w700 font--s14 color--grey mar--bottom__2 letterSpace--1"
          >
            UNTERNEHMEN
          </div>
          <a
            :href="theme.legal.privacy"
            target="_blank"
            v-if="theme.legal && theme.legal.privacy"
            class="font--s14 color--grey mar--bottom__1 noDec clickable noDec"
          >
            Datenschutz
          </a>
          <router-link
            class="font--s14 color--grey mar--bottom__1 noDec clickable"
            @click.native="linkNavigation"
            :to="`/${$route.params.organizationID}/data`"
            v-if="!theme.legal || !theme.legal.privacy"
          >
            Datenschutz
          </router-link>
          <a
            :href="theme.legal.termsOfUser"
            target="_blank"
            v-if="theme.legal && theme.legal.termsOfUser"
            class="font--s14 color--grey mar--bottom__1 noDec clickable noDec"
          >
            Geschäftsbedingungen
          </a>
          <router-link
            class="font--s14 color--grey mar--bottom__1 noDec clickable"
            @click.native="linkNavigation"
            :to="`/${$route.params.organizationID}/agb`"
            v-if="!theme.legal || !theme.legal.termsOfUser"
          >
            Geschäftsbedingungen
          </router-link>
          <a
            :href="theme.legal.imprint"
            target="_blank"
            v-if="theme.legal && theme.legal.imprint"
            class="font--s14 color--grey mar--bottom__1 noDec clickable noDec"
          >
            Impressum
          </a>
          <router-link
            class="font--s14 color--grey mar--bottom__1 noDec clickable"
            @click.native="linkNavigation"
            :to="`/${$route.params.organizationID}/imprint`"
            v-if="!theme.legal || !theme.legal.imprint"
          >
            Impressum
          </router-link>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  methods: {
    linkNavigation() {
      window.scrollTop = 0;
    },
  },
  computed: {
    theme() {
      /* eslint-disable */
      console.log(this.$store.getters.getTheme);
      return this.$store.getters.getTheme;
    },
  },
};
</script>

export default {
  setToken(state, token) {
    state.token = token;
  },
  setDoguToken(state, doguToken) {
    state.doguToken = doguToken;
  },
  setTheme(state, theme) {
    state.theme = theme;
  },
  setTitle(state, title) {
    state.title = title;
  },
  setUser(state, user) {
    state.user = user;
  },
  setCourses(state, courses) {
    state.courses = courses;
  },
  setCourseDetails(state, course) {
    state.courseDetails = course;
  },
  setPageProgress(state, { chapterID, pageID }) {
    const page = state.courseDetails.course
      .find((el) => el._id === chapterID)
      .pages.find((el) => el._id === pageID);
    if (page) {
      page.done = !page.done;
    }
  },
  setOrganizationID(state, id) {
    state.organizationID = id;
  },
  addToast(state, toast) {
    state.toasts.push({
      id: Date.now().toString(),
      type: toast.type,
      title: toast.title,
    });
  },
  deleteToast(state, toastID) {
    state.toasts = state.toasts.filter((el) => el.id !== toastID);
  },
  setErrorCodes(state, codes) {
    state.errorCodes = codes;
  },
  setSubscription(state, subscription) {
    state.subscription = subscription;
  },
  setCoopPartners(state, coopPartners) {
    state.coopPartners = coopPartners;
  },
  setCoop(state, coop) {
    state.coop = coop;
  },
  setCoupon(state, coupon) {
    state.coupon = coupon;
  },
  setCoopRecord(state, coopRecord) {
    state.coopRecord = coopRecord;
  },
  setDoguUser(state, doguUser) {
    state.doguUser = doguUser;
  },
  setLoginRes(state, loginRes) {
    state.loginRes = loginRes;
  },
  setDoguLoginRes(state, doguLoginRes) {
    state.doguLoginRes = doguLoginRes;
  },
};

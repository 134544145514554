<template>
  <div class="width--100 flex flex--column">
    <div
      class="video"
      v-if="
        page.pageType === 'VIDEO' &&
        page.video &&
        page.video.online &&
        page.video.online.id
      "
    >
      <div class="emptyDiv"></div>
      <!--<iframe id="apivideo_player" :src="'https://embed.api.video/vod/vi2z92fXZ1YZn4vSG57CNYCD'" width="100%" height="100%" frameborder="0" scrolling="no" allowfullscreen=""></iframe>-->
      <!-- <iframe
        id="iframe"
        :src="`https://player.vimeo.com/video/${
          page.video.online.id.split('/')[2]
        }?${
          page.video.online.link.split('/').length > 3
            ? 'h=' + page.video.online.link.split('/')[4] + '&'
            : ''
        }title=false&autoplay=1&color=BED0D0`"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      ></iframe> -->
      <!-- <video class="video-js vjs-theme-city"></video> -->

      <!-- <video-js
        id="video"
        class="video-js vjs-big-play-centered width&#45;&#45;100 height&#45;&#45;100"
        ref="videoPlayer"
        :poster="URL + page.thumbnail.link"
        preload="auto"
        data-setup='{ "techOrder": ["vimeo"], "sources": [{ "type": "video/vimeo", "src": "https://vimeo.com/23827073"}], "vimeo": { "color": "#fbc51b"} }'
        controls
      ></video-js> -->
      <div class="video_container">
        <video
          class="video-js vjs-theme-city"
          id="js_video"
          width="640"
          height="264"
        ></video>
      </div>
    </div>
    <!-- <source
        src="https://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8"
        type="application/x-mpegURL"
      /> -->
    <!-- <source
        src="https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_fmp4/master.m3u8"
        type="application/x-mpegURL"
      /> -->

    <div class="flex content--header contentHeader">
      <div
        class="video--move flex flex--center lineHeight--1 clickable"
        @click="prevPage"
      >
        <i class="ion-ios-arrow-back" />
      </div>
      <div
        class="flex--grow video--details flex flex--space__hor flex--center__vert"
      >
        <div class="flex flex--center__vert">
          <i
            class="ion-md-time mar--right__1 font--s2 lineHeight--1"
            v-if="page.duration"
          />
          <div class="font--s14">
            {{ page.duration ? `${page.duration} min` : "" }}
          </div>
        </div>
        <AccentButton
          :title="
            this.page.done ? 'Lektion abgeschlossen' : 'Lektion abschließen'
          "
          @onClick="toggleDone"
          :disabled="
            this.page.pageType === 'ASSIGNMENT'
              ? !result && !this.page.done
              : false
          "
        />
      </div>
      <div
        class="video--move flex flex--center lineHeight--1 clickable"
        @click="nextPage"
      >
        <i class="ion-ios-arrow-forward" />
      </div>
    </div>
    <div class="flex flex--center__hor content--title width--100 contentTitle">
      <div class="font--s3 font--w700">
        {{ page.title }}
      </div>
    </div>
    <div
      class="content--body pad--2 contentBody"
      v-if="
        page.description &&
        (page.pageType === 'ASSIGNMENT' || page.pageType === 'VIDEO')
      "
    >
      <div class="font--s14" v-html="page.description" />
    </div>
    <div
      class="flex flex--column pad--25"
      v-if="page.pageType === 'ASSIGNMENT'"
    >
      <div
        class="assignment mar--bottom__2"
        v-for="(assign, ind) in page.assignments"
        :key="assign._id"
      >
        <div class="font--s2 font--w700 mar--bottom__2">
          {{ ind + 1 }}. {{ assign.title }}
        </div>
        <div
          class="assignment--answer flex flex--center__vert clickable mar--bottom__1"
          v-for="answer in assign.answers"
          @click="toggleAnswer(assign._id, answer._id)"
          :key="answer._id"
        >
          <div
            class="assignment--answer__box circle border--light mar--right__2 flex flex--center"
            :class="{
              'assignment--answer__selected': selectedAnswers.find((el) =>
                el.answerIds.includes(answer._id)
              ),
            }"
          ></div>
          <div class="assignment--answer__text font--s16">
            {{ answer.text }}
          </div>
        </div>
      </div>
      <AccentButton title="Antworten überprüfen" @onClick="checkAnswers" />
    </div>

    <div class="flex welcome--content" v-if="page.pageType === 'AD'">
      <div class="card b-radius--5 overflowHidden">
        <div
          class="width--100 welcome--banner flex flex--center overflowHidden mar--bottom__2"
        >
          <img class="width--100" src="../../../assets/Banner.jpg" />
        </div>
        <div class="font--s16 pad--3" v-html="text" />
      </div>
    </div>

    <div class="content--footer width--100 pad--2 contentFooter">
      <div class="flex flex--column" v-if="page.downloads.length">
        <div class="font--s16 font--w700 downloadsText">Downloads:</div>
        <div class="flex downloadItems">
          <template v-if="isOamn">
            <!-- <i v-if="isOamn && download.mimeType.startsWith('audio')">audio</i> -->
            <template v-for="download in page.downloads">
              <a
                class="noDec flex font--s16 flex--center__vert border--light pad--1 b-radius--5 clickable bgColor--primary color--white mar--right__1 downloadItem"
                :key="download._id"
                v-if="!download.mimeType.startsWith('audio')"
                :href="`${URL}${download.memberLink}/${token}`"
                download
                :type="download.mimeType"
              >
                <i
                  v-if="isOamn && download.mimeType.endsWith('pdf')"
                  class="ion-md-document"
                />
                <div class="mar--right__2 fileName">
                  {{ download.name.replace("_CODT.", ".") }}
                </div>
                <i class="ion-md-download" />
              </a>
              <AudioPlayer
                :key="download._id"
                v-else
                :name="download.name"
                :file="`${URL}${download.memberLink}/${token}`"
              />
            </template>
          </template>
          <template v-else>
            <a
              class="noDec flex font--s16 flex--center__vert border--light pad--1 b-radius--5 clickable bgColor--primary color--white mar--right__1 downloadItem"
              v-for="download in page.downloads"
              :key="download._id"
              :href="`${URL}${download.memberLink}/${token}`"
              download
              :type="download.mimeType"
            >
              <i v-if="isOamn && download.mimeType.endsWith('pdf')">doc</i>

              <div class="mar--right__2">
                {{ download.name }}
              </div>
              <i class="ion-md-download" />
            </a>
          </template>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <Modal
        v-if="showResultModal"
        @toggle="showResultModal = false"
        title="Ergebnisse"
      >
        <div
          class="pad--2 width--100 flex flex--column flex--center font--s2"
          v-if="!result"
        >
          <div class="mar--bottom__1">
            Schade, leider waren deine Antworten nicht korrekt.
          </div>
          <div class="mar--bottom__2">Versuch es nocheinmal.</div>
          <AccentButton
            title="Wiederholen"
            @onClick="
              () => {
                this.showResultModal = false;
                this.selectedAnswers = [];
              }
            "
          />
        </div>
        <div
          class="pad--2 width--100 flex flex--column flex--center font--s2"
          v-if="result"
        >
          <div class="mar--bottom__1">
            Sehr gut, du hast alle Fragen richtig beantwortet.
          </div>
          <div class="mar--bottom__2">Mach weiter so!</div>
          <AccentButton
            title="Nächste Lektion"
            @onClick="
              () => {
                this.showResultModal = false;
              }
            "
          />
        </div>
      </Modal>
    </transition>
  </div>
</template>
<script>
import AccentButton from "@/components/reusable/AccentButton";
import Modal from "@/components/reusable/Modal";
import AudioPlayer from "@/components/reusable/AudioPlayer";
// import videojs from 'video.js';
// import '@videojs/http-streaming';
// import 'videojs-contrib-quality-levels';
// import 'videojs-hls-quality-selector'

import "video.js";
export default {
  name: "CourseContent",
  components: { Modal, AccentButton, AudioPlayer },
  data() {
    return {
      player: null,
      selectedAnswers: [],
      showResultModal: false,
      result: false,
      playerOptions: {
        autoplay: true,
        controls: true,
      },
      link: null,
    };
  },
  computed: {
    isOamn() {
      return (
        this.$store.getters.getOrganizationID === "62a8815e42be6e0012bda012"
      );
    },
    page() {
      /* eslint-disable */
      if (this.chapter.pages) {
        return (
          this.chapter.pages.find(
            (el) => el._id === this.$route.params.page
          ) || {
            downloads: [],
          }
        );
      }
      return { downloads: [] };
    },
    chapter() {
      if (this.$store.getters.getCourseDetails.course) {
        return (
          this.$store.getters.getCourseDetails.course.find(
            (el) => el._id === this.$route.params.chapter
          ) || { pages: [] }
        );
      }
      return { pages: [] };
    },
    courseDetails() {
      return this.$store.getters.getCourseDetails;
    },
    URL() {
      return this.$store.getters.getURL;
    },
    token() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    toggleDone() {
      this.$store.dispatch("setPageProgress", {
        courseID: this.$route.params.courseID,
        chapterID: this.$route.params.chapter,
        pageID: this.page._id,
        status: {
          done: !this.page.done,
        },
      });
    },
    nextPage() {
      const pageInd = this.chapter.pages.findIndex(
        (el) => el._id === this.page._id
      );
      const chapterInd = this.courseDetails.course.findIndex(
        (el) => el._id === this.chapter._id
      );
      if (pageInd >= this.chapter.pages.length - 1) {
        if (chapterInd >= this.courseDetails.course.length - 1) {
          this.$router.push({
            name: "CourseWelcome",
          });
        } else {
          this.$router.push({
            name: "ChapterOverview",
            params: {
              chapter: this.courseDetails.course[chapterInd + 1]._id,
              // page: this.courseDetails.course.chapters[chapterInd + 1].pages[0]._id,
            },
          });
        }
      } else {
        this.$router.push({
          name: "Page",
          params: {
            chapter: this.courseDetails.course[chapterInd]._id,
            page: this.courseDetails.course[chapterInd].pages[pageInd + 1]._id,
          },
        });
      }
    },
    prevPage() {
      const pageInd = this.chapter.pages.findIndex(
        (el) => el._id === this.page._id
      );
      const chapterInd = this.courseDetails.course.findIndex(
        (el) => el._id === this.chapter._id
      );
      if (pageInd <= 0) {
        if (chapterInd <= 0) {
          this.$router.push({
            name: "CourseWelcome",
          });
        } else {
          this.$router.push({
            name: "Page",
            params: {
              chapter: this.courseDetails.course[chapterInd - 1]._id,
              page: this.courseDetails.course[chapterInd - 1].pages[
                this.courseDetails.course[chapterInd - 1].pages.length - 1
              ]._id,
            },
          });
        }
      } else {
        this.$router.push({
          name: "Page",
          params: {
            chapter: this.courseDetails.course[chapterInd]._id,
            page: this.courseDetails.course[chapterInd].pages[pageInd - 1]._id,
          },
        });
      }
    },
    toggleAnswer(question, answer) {
      const exists = this.selectedAnswers.find(
        (el) => el.questionId === question
      );
      if (!exists) {
        this.selectedAnswers.push({
          questionId: question,
          answerIds: [answer],
        });
      } else if (exists.answerIds.includes(answer)) {
        exists.answerIds = exists.answerIds.filter((el) => el !== answer);
      } else {
        exists.answerIds.push(answer);
      }
      this.selectedAnswers = this.selectedAnswers.filter((el) => !el.length);
    },
    checkAnswers() {
      if (this.selectedAnswers.length !== this.page.assignments.length) {
        this.result = false;
      } else {
        let correct = true;
        this.page.assignments.forEach((el) => {
          let correctAnswers = el.answers.filter((el2) => el2.correct);
          correctAnswers = correctAnswers.map((el2) => el2._id);
          let answers = this.selectedAnswers.find(
            (el2) => el2.questionId === el._id
          ).answerIds;
          if (answers && answers.length === correctAnswers.length) {
            answers.forEach((el2) => {
              if (!correctAnswers.includes(el2)) {
                correct = false;
              }
            });
          } else {
            correct = false;
          }
        });
        if (correct) {
          this.$store.dispatch("setPageProgress", {
            courseID: this.$route.params.courseID,
            chapterID: this.$route.params.chapter,
            pageID: this.page._id,
            status: {
              done: true,
            },
          });
        }
        this.result = correct;
      }
      this.showResultModal = true;
    },
  },
  watch: {
    async page(newVal, oldVal) {
      if (!newVal.video) return;
      if (newVal.video && newVal.video.online.id !== oldVal.video.online.id) {
        this.link = null;

        const res = await this.$store.dispatch(
          "retrieveVideoDetails",
          "/videos/" + newVal.video.online.id.split("/")[2]
        );

        this.link = res.hls.link;

        var options = {
          controls: true,
          autoplay: false,
          preload: "auto",
          responsive: true,
          playbackRates: [0.5, 1, 1.5, 2],
        };
        this.player = videojs("js_video", options);

        this.player.src({
          type: "application/x-mpegURL",
          src: res.hls.link,
        });

        this.player.poster(res.thumbnail.link);
      }
    },
  },
  async mounted() {
    window.HELP_IMPROVE_VIDEOJS = false;
    if (
      this.page.video &&
      this.page.video.online &&
      this.page.video.online.id
    ) {
      const res = await this.$store.dispatch(
        "retrieveVideoDetails",
        "/videos/" + this.page.video.online.id.split("/")[2]
      );

      this.link = res.hls.link;

      var options = {
        controls: true,
        autoplay: false,
        preload: "auto",
        responsive: true,
        playbackRates: [0.5, 1, 1.5, 2],
      };
      this.player = videojs("js_video", options);

      this.player.src({
        type: "application/x-mpegURL",
        src: res.hls.link,
      });

      this.player.poster(res.thumbnail.link);

      // this.player.src([
      //   {
      //     type: "video/mp4",
      //     src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      //   },
      // ]);

      this.player.ready(function () {});

      this.player.on("timeupdate", () => {
        var progress = this.player.currentTime() / this.player.duration();
        if (progress > 0.95 && !this.page.done) {
          this.page.done = true;
          this.$store.dispatch("setPageProgress", {
            courseID: this.$route.params.courseID,
            chapterID: this.$route.params.chapter,
            pageID: this.page._id,
            status: {
              done: true,
            },
          });
        }
      });

      this.player.hlsQualitySelector({ displayCurrentQuality: true });

      //this.player.play();
      //   this.$refs.videoPlayer,
      //   this.playerOptions,
      //   function onPlayerReady(player) {
      //     console.log("onPlayerReady", this);
      //   }
      // );
      // this.player.autoplay = true;
      // this.player.src({
      //   src: res.hls.link,
      //   type: "application/x-mpegURL",
      //   withCredentials: false,
      // });
      // this.player.hlsQualitySelector({
      //   displayCurrentQuality: true,
      // });
    }

    // setTimeout(() => {
    //   if (this.page.pageType === "VIDEO") {
    //     let iframe = document.querySelector("iframe", {});
    //     let player = new Player(iframe);
    //     player.on("timeupdate", (progress) => {
    //       console.log(progress);
    //       if (progress.percent > 0.95 && !this.page.done) {
    //         this.$store.dispatch("setPageProgress", {
    //           courseID: this.$route.params.courseID,
    //           chapterID: this.$route.params.chapter,
    //           pageID: this.page._id,
    //           status: {
    //             done: true,
    //           },
    //         });
    //       }
    //     });
    //   }
    // }, 1000);
    // /* eslint-disable */
    // setTimeout(() => {
    //     const iframe = document.getElementsByClassName('ext_dl-label');
    //     console.log(iframe);
    //     const el = iframe.innerHtml;
    //     console.log(el);
    // }, 1000);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

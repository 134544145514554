/* eslint-disable no-console */
import axios from "axios";
import store from "@/store";
import router from "../router";
import { extendTheme } from "../helpers/theme_helper";
import { DOGU } from "@/consts";
//import { set } from "core-js/core/dict";

// let API = 'https://api.okourse.dev.neon.dev';
let API = process.env.VUE_APP_DOMAIN_BACKEND + "/members/";
console.log(window.location.href);
// if (window.location.href.split('/')[2] !== 'member.okourse.com') {
//     API = 'https://api.' + window.location.href.split('/')[2] + '/members/';
//     console.log(API);
// }

axios.defaults.withCredentials = true;
axios.defaults.timeout = 30000;

function moveIdsToStart(objects, ids) {
  const movedObjects = [];
  const remainingObjects = [];

  for (const id of ids) {
    const found = objects.find((obj) => obj.course._id === id);
    if (found) {
      movedObjects.push(found);
    }
  }
  for (const obj of objects) {
    if (!ids.includes(obj.course._id)) {
      remainingObjects.push(obj);
    }
  }

  return [...movedObjects, ...remainingObjects];
}

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let errorMsg;
    console.log(error.response.data);
    if (
      error.response.data.status === 400 &&
      error.response.data.msg === "Refresh Token does not exist!"
    )
      return Promise.reject(error);
    if (typeof error.response.data.msg === "number") {
      errorMsg = store.getters.getErrorMsg(
        error.response.status,
        error.response.data.msg
      );
    } else {
      errorMsg = error.response.data.msg;
    }
    if (error.response && errorMsg) {
      store.commit("addToast", {
        type: "error",
        title: errorMsg,
      });
    } else {
      if (error.response.data.msg === "Refresh Token does not exist!") {
        return;
      }
      store.commit("addToast", {
        type: "error",
        title: "Es ist ein Fehler aufgetreten.",
      });
    }
    return Promise.reject(error);
  }
);

export default {
  // AUTHENTICATION ROUTES --------------------------------------------------------------------------------------

  async fetchOrganization({ commit, getters }, domain) {
    let success = true;
    const res = await axios
      .post(getters.getURL + "hostname", { domain })
      .catch((error) => {
        console.log(error.response.data.msg);
        success = false;
      });
    if (success) {
      commit("setOrganizationID", res.data._id);
      return res.data._id;
    }
  },

  async getDoguUser({ commit, getters }) {
    const res = await axios.get(getters.getDoguBackendUrl + "users/me", {
      headers: {
        Authorization: "Bearer " + getters.getDoguToken,
      },
      withCredentials: false,
    });
    commit("setDoguUser", res.data);
  },

  async login({ commit, getters }, { organizationID, credentials }) {
    let success = true;
    const res = await axios
      .post(API + "organizations/" + organizationID + "/login", credentials, {
        withCredentials: true,
      })
      .catch((error) => {
        // eslint-disable-next-line
        // console.log(error.response.data.msg);
        success = false;
        return error.response;
      });
    if (res && success) {
      console.log(res.headers);
      commit("setToken", res.headers["x-auth-token"]);
      if (credentials.rememberLogin) {
        localStorage.setItem(
          "x-auth-token-" + getters.getOrganizationID,
          res.headers["x-auth-token"]
        );
        sessionStorage.removeItem("x-auth-token-" + getters.getOrganizationID);
      } else {
        sessionStorage.setItem(
          "x-auth-token-" + getters.getOrganizationID,
          res.headers["x-auth-token"]
        );
        localStorage.removeItem("x-auth-token-" + getters.getOrganizationID);
      }
      axios.defaults.headers.common["x-auth-token"] = getters.getToken;
    }
    return res;
  },

  async loginDogu({ commit, getters }, { credentials }) {
    let success = true;
    const res = await axios
      .post(
        getters.getDoguBackendUrl + "authentication",
        {
          email: credentials.email,
          password: credentials.password,
          strategy: "local",
          secret: credentials.secret,
        },
        { withCredentials: false }
      )
      .catch((error) => {
        // eslint-disable-next-line
        // console.log(error.response.data.msg);
        success = false;
        error.response.api = "dogu";
        commit("setDoguLoginRes", error.response);
        return error.response;
      });
    if (res && success) {
      const doguToken = res.data.accessToken;
      const doguUser = res.data.user;

      commit("setDoguToken", doguToken);
      commit("setDoguUser", doguUser);

      if (credentials.rememberLogin) {
        localStorage.setItem("x-auth-token-dogu", doguToken);
        localStorage.setItem("x-auth-user-dogu", doguUser);
        sessionStorage.removeItem("x-auth-token-dogu");
        sessionStorage.removeItem("x-auth-user-dogu");
      } else {
        sessionStorage.setItem("x-auth-token-dogu", doguToken);
        sessionStorage.setItem("x-auth-user-dogu", doguUser);
        localStorage.removeItem("x-auth-token-dogu");
        localStorage.removeItem("x-auth-user-dogu");
      }

      commit("setDoguLoginRes", res);
    }
    return res;
  },

  async refreshToken({ getters, commit }) {
    axios.defaults.headers.common["x-auth-token"] = getters.getToken;
    if (!getters.getToken) throw Error;
    const res = await axios
      .post(API + `refresh-token`, {}, { withCredentials: true })
      .catch();
    if (res) {
      commit("setToken", res.headers["x-auth-token"]);
      if (localStorage.getItem("x-auth-token-" + getters.getOrganizationID)) {
        localStorage.setItem(
          "x-auth-token-" + getters.getOrganizationID,
          res.headers["x-auth-token"]
        );
        sessionStorage.removeItem("x-auth-token-" + getters.getOrganizationID);
      } else {
        sessionStorage.setItem(
          "x-auth-token-" + getters.getOrganizationID,
          res.headers["x-auth-token"]
        );
        localStorage.removeItem("x-auth-token-" + getters.getOrganizationID);
      }
      axios.defaults.headers.common["x-auth-token"] = getters.getToken;
    }
  },

  async logoutUser({ commit, getters }) {
    await axios.post(`${getters.getURL}logout/member`, {}).catch((error) => {
      console.log(error.response.data.msg);
    });
    sessionStorage.removeItem("x-auth-token-" + getters.getOrganizationID);
    localStorage.removeItem("x-auth-token-" + getters.getOrganizationID);
    sessionStorage.removeItem("x-auth-token-dogu");
    sessionStorage.removeItem("x-auth-user-dogu");
    localStorage.removeItem("x-auth-token-dogu");
    localStorage.removeItem("x-auth-user-dogu");
    commit("setToken", null);
    commit("setDoguUser", null);
    commit("setDoguToken", null);
    axios.defaults.headers.common["x-auth-token"] = null;
  },

  async deleteUser({ dispatch, getters }) {
    await axios
      .delete(`${getters.getURL}members/me`)
      .catch((error) => {
        console.log(error.response.data.msg);
      })
      .then(() => {
        dispatch("logoutUser");
      });
  },

  async retrieveOwnUser({ commit }) {
    const res = await axios.get(API + "me");
    commit("setUser", res.data);
  },

  async retrieveDoguOwnUser({ getters, commit }) {
    const res = await axios.get(getters.getDoguBackendUrl + "v1/users/me", {
      headers: {
        Authorization: "Bearer " + getters.getDoguToken,
      },
      withCredentials: false,
    });
    commit("setDoguUser", res.data);
  },

  async updateOwnUser({ dispatch }, data) {
    await axios.put(API + "me", data);
    dispatch("retrieveOwnUser");
  },

  // PASSWORD RESET ROUTES ------------------------------------------------------------------------------------------------

  async checkToken(store, token) {
    const res = await axios
      .post(API + "password/valid", token)
      .catch(() => false);
    return res;
  },

  async forgotPassword(store, { organizationID, email }) {
    const res = await axios.post(
      API + "organizations/" + organizationID + "/password/forgot",
      { email }
    );
    return res.data;
  },

  async resetPassword(store, password) {
    await axios.post(API + "password/reset", password);
  },

  // COOURSE ROUTES ----------------------------------------------------------------------------------------------

  async retrieveCourses({ commit }, organizationID) {
    const pinnedCourses =
      organizationID === DOGU ? ["64118c72efde0c0015e2cee5"] : [];
    const res = await axios.get(API + "courses");
    commit("setCourses", moveIdsToStart(res.data, pinnedCourses));
  },

  async retrieveCourseDetails({ getters, commit }, courseID) {
    const res = await axios.get(API + "courses/" + courseID).catch((error) => {
      // eslint-disable-next-line
      if (error.response.data.msg === 17) {
        router.push("/" + getters.getOrganizationID);
      }
    });
    commit("setCourseDetails", res.data);
  },

  async setPageProgress({ dispatch }, { courseID, chapterID, pageID, status }) {
    await axios.put(
      API + `courses/${courseID}/chapters/${chapterID}/pages/${pageID}`,
      status
    );
    dispatch("retrieveCourseDetails", courseID);
  },

  async createCertificate({ dispatch }, { courseID, data }) {
    await axios.post(API + "courses/" + courseID + "/certificate", data);
    dispatch("retrieveCourseDetails", courseID);
  },

  async importCourses({ dispatch }, data) {
    await axios.post(API + "courses/import", data);
    dispatch("retrieveCourses");
  },

  async retrieveVideoDetails(_, videoId) {
    const res = await axios.post(API + "video-details", { videoId });
    return res.data;
  },

  // THEME ROUTES -------------------------------------------------------------------------------------------------

  async retrieveCourseTheme({ commit, state }, organizationID) {
    const res = await axios.get(
      state.URL + "organizations/" + organizationID + "/themes"
    );

    const extendedTheme = extendTheme(organizationID, res.data.theme);

    commit("setTheme", extendedTheme);
    commit("setTitle", res.data.title);
  },

  // ERROR CODES ROUTES ------------------------------------------------------------------------------------------

  async retrieveErrorCodes({ commit, state }) {
    const { data } = await axios.post(state.URL + "error", {
      language: "de", // todo dynamic base on user language selection
    });
    commit("setErrorCodes", data);
  },

  // SUBSCRIPTION ROUTES ------------------------------------------------------------------------------------------
  async retrieveSubscription({ commit, getters }) {
    const res = await axios.get(
      getters.getDoguBackendUrl + "v1/elopage-unsubscribe-link",
      {
        headers: {
          Authorization: "Bearer " + getters.getDoguToken,
        },
        withCredentials: false,
      }
    );
    commit("setSubscription", res.data);

    // // TODO remove fake response
    // const data = {
    //   subscribed: true,
    //   subscriptionAdvertising: {
    //     _id: "5f24859c49d8927c5a0dd4e2",
    //     title: null,
    //     externalId: null,
    //     thumbnail: null,
    //     trailerVideoId: null,
    //     price: null,
    //     orderLink: null,
    //     uploads: [
    //       {
    //         file: "5f24948f4da4f07f1a72ba04",
    //         thumbnail: "5f24948f4da4f07f1a72ba05",
    //         mimetype: "image/jpeg",
    //         _id: "5f24948f4da4f07f1a72ba06",
    //       },
    //     ],
    //     createdAt: "2020-07-31T20:57:00.714Z",
    //     updatedAt: "2020-07-31T22:00:47.871Z",
    //     __v: 0,
    //   },
    // };
    // commit("setSubscription", data);
  },

  // COOP ROUTES ------------------------------------------------------------------------------------------
  async retrieveCoop({ commit, getters }) {
    const res = await axios.get(
      getters.getDoguBackendUrl + "v1/coop-partners?isActive=true",
      {
        headers: {
          Authorization: "Bearer " + getters.getDoguToken,
        },
        withCredentials: false,
      }
    );
    commit("setCoopPartners", res.data);
  },

  // Coupon ROUTES ------------------------------------------------------------------------------------------
  async retrieveCoupon({ commit, getters }, { partnerId }) {
    commit("setCoupon", null);
    const res = await axios.get(
      getters.getDoguBackendUrl + "v1/coupons/pick?partner=" + partnerId,
      {
        headers: {
          Authorization: "Bearer " + getters.getDoguToken,
        },
        withCredentials: false,
      }
    );

    commit("setCoupon", res.data);
  },

  // Coop record
  async createCoopRecord({ commit, getters }, { form, partnerId }) {
    const body = {
      partner: partnerId,
      user: getters.getDoguUser._id,
      concerns: "dog insurance",
      data: {
        person: {
          firstName: form.firstName,
          lastName: form.lastName,
          street: form.street,
          zip: form.zip,
          city: form.city,
          country: form.country,
        },
        dogs: [
          ...(form.firstDogName && form.firstDogChipId
            ? [
                {
                  name: form.firstDogName,
                  chipId: form.firstDogChipId,
                },
              ]
            : []),
          ...(form.secondDogName && form.secondDogChipId
            ? [
                {
                  name: form.secondDogName,
                  chipId: form.secondDogChipId,
                },
              ]
            : []),
        ],
      },
    };

    const res = await axios.post(
      getters.getDoguBackendUrl + "v1/coop-records",
      body,
      {
        headers: {
          Authorization: "Bearer " + getters.getDoguToken,
        },
        withCredentials: false,
      }
    );
    commit("setCoopRecord", res.data);
  },

  async patchCoopRecord({ commit, getters }, { recordId, data }) {
    const body = {
      concerns: "dog insurance",
      data,
    };

    const res = await axios.patch(
      getters.getDoguBackendUrl + "v1/coop-records/" + recordId,
      body,
      {
        headers: {
          Authorization: "Bearer " + getters.getDoguToken,
        },
        withCredentials: false,
      }
    );
    commit("setCoopRecord", res.data);
  },

  async getCoopRecord({ commit, getters }, { partnerId }) {
    const res = await axios
      .get(getters.getDoguBackendUrl + "v1/coop-records?partner=" + partnerId, {
        headers: {
          Authorization: "Bearer " + getters.getDoguToken,
        },
        withCredentials: false,
      })
      .catch((err) => {
        console.error(err);
      });

    const data = res.data.data[0];
    commit("setCoopRecord", data);
  },
};

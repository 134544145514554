<template>
  <div
    class="auth--wrapper flex flex--center"
    :style="{ '--primary-color': theme.primary || '#2b2b2b' }"
  >
    <div class="auth--container pad--25">
      <div class="flex flex--center width--100">
        <img
          class="width--max__100pc height--max__20rem clickable mar--bottom__2"
          :src="`${url}${theme.bannerLogo.link}`"
          v-if="theme.bannerLogo.link"
        />
      </div>
      <div class="font--s2 font--w700 mar--bottom__2">
        Passwort zurücksetzen
      </div>
      <label class="form--label" for="password"> Neues Passwort </label>
      <input
        class="form--input__text mar--bottom__1"
        id="password"
        type="password"
        v-model="password"
      />
      <label class="form--label" for="password_check">
        Neues Passwort wiederholen
      </label>
      <input
        class="form--input__text mar--bottom__2"
        id="password_check"
        type="password"
        v-model="passwordCheck"
      />
      <div class="mar--bottom__1">
        <AccentButton
          :filled="true"
          :full-width="true"
          :loading="loading"
          title="Passwort zurücksetzen"
          @onClick="resetPassword"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AccentButton from "@/components/reusable/AccentButton";
export default {
  name: "ForgotPassword",
  components: { AccentButton },
  data() {
    return {
      password: null,
      passwordCheck: null,
      token: null,
      loading: false,
    };
  },
  computed: {
    theme() {
      return this.$store.getters.getTheme;
    },
    title() {
      return this.$store.getters.getTitle;
    },
    url() {
      return this.$store.getters.getURL;
    },
  },
  methods: {
    resetPassword() {
      if (this.password !== this.passwordCheck) {
        this.$store.commit("addToast", {
          type: "warning",
          title: "Die Passwörter stimmen nicht überein",
        });
        return;
      }
      if (this.password.length < 8) {
        this.$store.commit("addToast", {
          type: "warning",
          title: "Das Passwort muss mindestens 8 Stellen haben",
        });
        return;
      }
      let success = true;
      this.loading = true;
      this.$store
        .dispatch("resetPassword", {
          secret: this.$route.params.sessionToken,
          password: this.password,
          passwordCheck: this.passwordCheck,
        })
        .catch(() => {
          success = false;
        })
        .then(() => {
          // eslint-disable-next-line
          console.log("Success");
          if (success) {
            this.$router.push(`/${this.$route.params.organizationID}/login`);
          }
        });
      this.loading = false;
    },
  },
  async mounted() {
    let success = true;
    await this.$store
      .dispatch("retrieveCourseTheme", this.$route.params.organizationID)
      .catch(() => {
        this.$store.commit("setTheme", {
          actions: [],
          primary: "#114B5F",
        });
        success = false;
      });
    /* eslint-disable */
    console.log(this.theme);
    console.log(success);
    if (!success) return;
    document.title = this.title;
    const link = document.createElement("link");
    const oldLink = document.getElementById("dynamic-favicon");
    link.id = "dynamic-favicon";
    link.rel = "shortcut icon";
    link.href = `${this.url}${this.theme.faviconLogo.link}`;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
    this.$store
      .dispatch("checkToken", { secret: this.$route.params.sessionToken })
      .then((worked) => {
        if (!worked) {
          this.$router.push("/" + this.$route.params.organizationID + "/login");
        }
      });
  },
};
</script>

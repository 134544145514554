<template>
  <div
    class="width--100 height--100 pad--25 flex flex--column flex--center__vert welcomePage"
  >
    <div class="welcome--wrapper flex welcomeWrapper">
      <div
        class="flex flex--space__hor flex--start__vert mar--bottom__2 welcome--header welcomeHeader"
      >
        <div
          class="welcome--title font--w700 lineHeight--12 flex flex--column width--100"
        >
          {{ title }}
          <div
            class="font--s14 color--grey mar--left__1"
            v-if="course.course.instructor"
          >
            von {{ course.course.instructor.firstName }}
            {{ course.course.instructor.lastName }}
          </div>
        </div>
        <AccentButton title="Weiterschauen" filled="true" @onClick="resume" />
      </div>
      <div class="flex welcomeTabs">
        <div
          class="font--s2 font--w700 mar--2 clickable color--grey transition border--bottom__trans"
          @click="page = 0"
          :class="{ 'welcome--select': page === 0 }"
        >
          Übersicht
        </div>
        <div
          class="font--s2 font--w700 mar--2 clickable color--grey transition border--bottom__trans"
          @click="page = 1"
          v-if="hasOverview"
          :class="{ 'welcome--select': page === 1 }"
        >
          Kapitel
        </div>
        <div
          class="font--s2 font--w700 mar--2 clickable color--grey transition border--bottom__trans"
          @click="page = 2"
          v-if="
            hasCertificate ||
            (progress === 100 && course && course.course.certificationPossible)
          "
          :class="{ 'welcome--select': page === 2 }"
        >
          Zertifikat
        </div>
        <div
          class="font--s2 font--w700 mar--2 clickable color--grey transition border--bottom__trans"
          @click="
            {
              page = 3;
              selectCoopPartner();
            }
          "
          v-if="hasCoop"
          :class="{ 'welcome--select': page === 3 }"
        >
          CLUB-Partner
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <div
          class="flex welcome--content width--100 welcomeContent"
          v-if="page === 0 && hasOverview"
          :key="'overview'"
        >
          <div
            class="card b-radius--5 overflowHidden width--100 welcome--banner welcomeBanner"
          >
            <div
              class="width--100 aspect--169"
              v-if="course.course.headerImage.link"
            >
              <img
                class="width--100 absolute top--0"
                :src="url + course.course.headerImage.link"
              />
            </div>
            <div
              class="font--s16 pad--3"
              v-html="course.course.welcomeText"
            ></div>
          </div>
          <div class="grid welcome--ads welcomeAds">
            <div class="flex flex--center" v-for="ad in ads" :key="ad._id">
              <div
                class="card b-radius--5 flex font--s16 color--white clickable welcome--ads__item relative overflowHidden flex flex--column"
                @click="openLink(ad.link)"
              >
                <img :src="url + ad.thumbnail.link" class="width--100" />
                <div
                  class="pad--2 bgColor--white width--100"
                  v-if="ad.description"
                >
                  <p class="font--s16 color--black">
                    {{ ad.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex--column"
          v-if="page === 1 || (page === 0 && !hasOverview)"
          :key="'chapters'"
        >
          <div
            class="card bgColor--white b-radius--5 mar--bottom__1 bgColor--white"
            v-for="chapter in courseDetails.course"
            :key="chapter._id"
          >
            <div
              class="navBar--chapter clickable pad--2"
              @click="expandChapter(chapter._id)"
            >
              <div
                class="flex flex--space__hor font--s16 font--w700 lineHeight--1"
              >
                <div class="">
                  {{ chapter.title }}
                </div>
                <i
                  class="ion-ios-arrow-forward flex flex--center navBar--chapter__expand"
                  :class="{
                    'rotate--90': !expandedChapters.includes(chapter._id),
                  }"
                />
              </div>
            </div>
            <div
              class="navBar--page font--s14 flex--center__vert flex--space__hor pad--2 clickable lineHeight--14"
              v-for="page in chapter.pages"
              :key="page._id"
              @click.self="
                accessible(chapter._id)
                  ? null
                  : clickPage(chapter._id, page._id)
              "
              :class="{
                hide: !expandedChapters.includes(chapter._id),
                flex: expandedChapters.includes(chapter._id),
                'bgColor--greyLight': $route.params.page === page._id,
              }"
            >
              <div
                class="flex"
                :class="{
                  'color--primary': $route.params.page === page._id,
                  'font--w700': $route.params.page === page._id,
                }"
              >
                <div
                  class="navBar--page__progress flex flex--center clickable mar--right__1"
                  :class="{ 'navBar--page__done': page.done }"
                  @click="toggleDone(chapter._id, page)"
                >
                  <i
                    class="ion-md-checkmark"
                    v-if="page.done && !accessible(chapter._id)"
                  />
                  <i class="ion-md-lock" v-if="accessible(chapter._id)" />
                </div>
                <div
                  @click.self="
                    accessible(chapter._id)
                      ? null
                      : clickPage(chapter._id, page._id)
                  "
                >
                  {{ page.title }}
                </div>
              </div>
              <div class="font--s1">
                {{ page.duration ? `${page.duration} min` : "" }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex--column" v-if="page === 2" :key="'certificate'">
          <div
            class="card bgColor--white b-radius--5 width--100 pad--25 flex flex--column"
          >
            <h1 class="mar--bottom__2">Dein Zertifikat</h1>
            <div class="flex flex--column" v-if="hasCertificate">
              <div class="flex mar--bottom__1">
                <div class="flex mar--right__1">
                  <a
                    :href="url + courseDetails.certificate.link"
                    target="_blank"
                    class="noDec"
                  >
                    <AccentButton
                      title="Zertifikat herunterladen"
                      filled="true"
                    />
                  </a>
                </div>
                <div class="flex">
                  <a
                    :href="
                      'https://okourse.com/certificates/' +
                      courseDetails.certificate.link.split('/')[1]
                    "
                    target="_blank"
                    class="noDec"
                  >
                    <AccentButton title="Zertifikat anzeigen" />
                  </a>
                </div>
              </div>
              <div class="form--label">Öffentlicher Link zum Zertifikat:</div>
              <div class="flex">
                <div
                  class="card bgColor--greyLight b-radius--5 flex clickable pad--1 flex--center__vert"
                >
                  <ActionButton
                    light="true"
                    icon="ion-ios-copy"
                    class="mar--right__1"
                    @onClick.stop.prevent="copyLink"
                  />
                  <div class="font--s16">
                    {{
                      "https://okourse.com/certificates/" +
                      courseDetails.certificate.link.split("/")[1]
                    }}
                  </div>
                  <input
                    class="pad--1 font--s16 width--100"
                    id="testing-code"
                    type="hidden"
                    :value="
                      'https://okourse.com/certificates/' +
                      courseDetails.certificate.link.split('/')[1]
                    "
                  />
                </div>
              </div>
            </div>
            <div class="flex" v-if="progress === 100 && !hasCertificate">
              <AccentButton
                title="Zertifikat generieren"
                filled="true"
                @onClick="toggleCertificate"
              />
            </div>
          </div>
        </div>
        <div
          class="flex welcome--content width--100 welcomeContent"
          v-if="page === 3"
          :key="'coop'"
        >
          <div :key="this.componentKey">
            <div v-if="!this.doguToken">
              <h1>
                <strong
                  >Bitte bestätige einmalig deine Login-Daten, um deine
                  Partner-Vorteile zu aktivieren:</strong
                >
              </h1>
              <LoginScreenDogU :onSuccess="this.onLoginSuccess" />
            </div>
            <div class="flex" v-else>
              <div
                v-if="this.selectedCoopPartner"
                class="card b-radius--5 overflowHidden width--100"
              >
                <div class="coop_card_expanded">
                  <div class="coop_card_expanded__header">
                    <div
                      class="coop_card_expanded__header__cover"
                      :style="{
                        backgroundImage: `url(\'${doguBackendUrl}v1/uploads/fetch/${selectedCoopPartner.headerImage.file}?authentication=${doguToken}\')`,
                      }"
                    ></div>
                    <div class="coop_card__logo big">
                      <img
                        :src="`${doguBackendUrl}v1/uploads/fetch/${selectedCoopPartner.logo.thumbnail}?authentication=${doguToken}`"
                      />
                    </div>
                  </div>
                  <div class="coop_card_expanded__content">
                    <div class="coop_card_expanded__content__title">
                      {{ this.selectedCoopPartner.title }}
                    </div>
                    <div class="coop_card_expanded__content__subtitle">
                      {{ this.selectedCoopPartner.subtitle }}
                    </div>
                    <div
                      class="coop_card_expanded__content__text"
                      v-html="selectedCoopPartner.details"
                    ></div>
                    <div
                      v-if="
                        !isHanseMerkur && !isDrSam && selectedCoopPartner.link
                      "
                      style="max-width: 250px; margin: 50px auto"
                    >
                      <AccentButton
                        title="Zum Partner"
                        filled="true"
                        style="margin-top: 4rem; margin-bottom: 4rem"
                        @onClick="openLink(selectedCoopPartner.link)"
                      />
                    </div>
                  </div>
                  <div v-if="isHanseMerkur">
                    <div
                      v-if="coopRecord"
                      style="
                        width: 60%;
                        margin: 0 auto;
                        margin-bottom: 4rem;
                        font-size: 16px;
                      "
                    >
                      <div
                        style="
                          font-size: 12px;
                          border-bottom: 1px solid #eee;
                          padding-bottom: 2rem;
                        "
                      >
                        Dir ist ein Fehler bei der Eingabe unterlaufen? Dann
                        melde dich beim Support unter
                        <a
                          style="color: #97bb54"
                          href="mailto:info@doguniversity.de"
                          >info@doguniversity.de</a
                        >
                      </div>
                      <div class="mar--bottom__2"></div>
                      <div class="form--label mar--bottom__1">
                        Persönliche Informationen *
                      </div>
                      <table>
                        <tbody>
                          <tr>
                            <td class="color--grey" style="padding-right: 2rem">
                              Vorname
                            </td>
                            <td>
                              {{
                                coopRecord &&
                                coopRecord.data &&
                                coopRecord.data.person
                                  ? coopRecord.data.person.firstName
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="color--grey" style="padding-right: 2rem">
                              Nachname
                            </td>
                            <td>
                              {{
                                coopRecord &&
                                coopRecord.data &&
                                coopRecord.data.person
                                  ? coopRecord.data.person.lastName
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="color--grey" style="padding-right: 2rem">
                              Adresse
                            </td>
                            <td>
                              {{
                                coopRecord &&
                                coopRecord.data &&
                                coopRecord.data.person
                                  ? coopRecord.data.person.street + ", "
                                  : ""
                              }}
                              {{
                                coopRecord &&
                                coopRecord.data &&
                                coopRecord.data.person
                                  ? coopRecord.data.person.zip
                                  : ""
                              }}
                              {{
                                coopRecord &&
                                coopRecord.data &&
                                coopRecord.data.person
                                  ? coopRecord.data.person.city + ", "
                                  : ""
                              }}
                              {{
                                coopRecord &&
                                coopRecord.data &&
                                coopRecord.data.person
                                  ? coopRecord.data.person.country
                                  : ""
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        class="form--label mar--bottom__1"
                        style="margin-top: 3rem"
                      >
                        Versicherungsinformationen zu deinen Hunden
                      </div>
                      <p>
                        Du kannst mit deinem CLUB-Abo bis zu <b>2 Hunde</b> mit
                        der HanseMerkur-Giftköderversicherung versichern lassen.
                      </p>
                      <div v-if="coopRecord && coopRecord.data.dogs.length > 0">
                        <div
                          class="form--label mar--bottom__1"
                          style="margin-top: 3rem"
                        >
                          1. Hund
                        </div>
                        <table>
                          <tbody>
                            <tr>
                              <td
                                class="color--grey"
                                style="padding-right: 2rem"
                              >
                                Name
                              </td>
                              <td>{{ coopRecord.data.dogs[0].name }}</td>
                            </tr>
                            <tr>
                              <td
                                class="color--grey"
                                style="padding-right: 2rem"
                              >
                                Chip-Nr.
                              </td>
                              <td>{{ coopRecord.data.dogs[0].chipId }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-if="coopRecord.data.dogs.length > 1">
                        <div
                          class="form--label mar--bottom__1"
                          style="margin-top: 3rem"
                        >
                          2. Hund
                        </div>
                        <table>
                          <tbody>
                            <tr>
                              <td
                                class="color--grey"
                                style="padding-right: 2rem"
                              >
                                Name
                              </td>
                              <td>{{ coopRecord.data.dogs[1].name }}</td>
                            </tr>
                            <tr>
                              <td
                                class="color--grey"
                                style="padding-right: 2rem"
                              >
                                Chip-Nr.
                              </td>
                              <td>{{ coopRecord.data.dogs[1].chipId }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-else>
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            margin-top: 2rem;
                          "
                          v-if="!addSecondDog"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="ionicon"
                            viewBox="0 0 512 512"
                            style="
                              width: 32px;
                              height: 32px;
                              display: inline-block;
                              margin-right: auto;
                              margin-left: auto;
                              color: #97bb54;
                              cursor: pointer;
                            "
                            @click="addSecondDog = true"
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                              d="M256 112v288M400 256H112"
                            />
                          </svg>
                        </div>
                        <div v-if="addSecondDog" style="margin-top: 2rem">
                          <label
                            for="first_name"
                            class="form--label mar--bottom__1"
                            >2. Hund</label
                          >
                          <input
                            id="second_dog_name"
                            type="text"
                            class="form--input form--input__text mar--bottom__1"
                            placeholder="Name"
                            v-model="secondDogName"
                          />
                          <input
                            id="second_dog_id"
                            type="text"
                            class="form--input form--input__text mar--bottom__1"
                            placeholder="Chip-Nr."
                            v-model="secondDogChipId"
                          />
                          <AccentButton
                            title="Speichern"
                            filled="true"
                            style="margin-top: 1rem; margin-bottom: 4rem"
                            @onClick="saveSecondDog()"
                          />
                        </div>
                      </div>
                      <div style="margin-top: 4rem" class="mar--bottom__1">
                        Es gelten die
                        <a
                          href="https://doguniversity.de/versicherungsbedingungen-hm"
                          target="_blank"
                          style="font-weight: bold; color: #97bb54"
                          >Versicherungsbedingungen</a
                        >.
                      </div>
                      <AccentButton
                        title="Schadensfall melden"
                        filled="true"
                        light="true"
                        style="margin-top: 0.5rem; margin-bottom: 4rem"
                        @onClick="openLink(selectedCoopPartner.link)"
                      />
                    </div>
                    <div v-if="!coopRecord" class="coop_form">
                      <div class="mar--bottom__2"></div>
                      <label for="first_name" class="form--label mar--bottom__1"
                        >Persönliche Informationen *</label
                      >
                      <input
                        id="first_name"
                        type="text"
                        class="form--input form--input__text mar--bottom__1"
                        placeholder="Vorname"
                        v-model="firstName"
                      />
                      <input
                        id="last_name"
                        type="text"
                        class="form--input form--input__text mar--bottom__1"
                        placeholder="Nachname"
                        v-model="lastName"
                      />
                      <input
                        id="country"
                        type="text"
                        class="form--input form--input__text"
                        value="Deutschland"
                        disabled
                        v-model="country"
                      />
                      <div class="mar--bottom__1">
                        Wohnsitz in Deutschland erforderlich
                      </div>
                      <input
                        id="street"
                        type="text"
                        class="form--input form--input__text mar--bottom__1"
                        placeholder="Straße"
                        v-model="street"
                      />
                      <div class="flex" style="gap: 2rem">
                        <input
                          id="zip_code"
                          type="text"
                          class="form--input form--input__text mar--bottom__1"
                          style="flex: 1"
                          placeholder="PLZ"
                          v-model="zip"
                        />
                        <input
                          id="city"
                          type="text"
                          class="form--input form--input__text mar--bottom__2"
                          placeholder="Stadt"
                          style="flex: 2"
                          v-model="city"
                        />
                      </div>
                      <div class="mar--bottom__2"></div>
                      <label for="first_name" class="form--label"
                        >Informationen zu deinem Hund</label
                      >
                      <p class="mar--bottom__1">
                        Du kannst mit deinem CLUB-Abo bis zu <b>2 Hunde</b> mit
                        der HanseMerkur-Giftköderversicherung versichern lassen.
                      </p>
                      <label for="first_name" class="form--label mar--bottom__1"
                        >1. Hund</label
                      >
                      <input
                        id="first_dog_name"
                        type="text"
                        class="form--input form--input__text mar--bottom__1"
                        placeholder="Name"
                        v-model="firstDogName"
                      />
                      <input
                        id="first_dog_id"
                        type="text"
                        class="form--input form--input__text mar--bottom__2"
                        placeholder="Chip-Nr."
                        v-model="firstDogChipId"
                      />
                      <div
                        style="display: flex; justify-content: center"
                        v-if="!addSecondDog"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="ionicon"
                          viewBox="0 0 512 512"
                          style="
                            width: 32px;
                            height: 32px;
                            display: inline-block;
                            margin-right: auto;
                            margin-left: auto;
                            color: #97bb54;
                            cursor: pointer;
                          "
                          @click="addSecondDog = true"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="32"
                            d="M256 112v288M400 256H112"
                          />
                        </svg>
                      </div>
                      <div v-if="addSecondDog">
                        <label
                          for="first_name"
                          class="form--label mar--bottom__1"
                          >2. Hund</label
                        >
                        <input
                          id="second_dog_name"
                          type="text"
                          class="form--input form--input__text mar--bottom__1"
                          placeholder="Name"
                          v-model="secondDogName"
                        />
                        <input
                          id="second_dog_id"
                          type="text"
                          class="form--input form--input__text mar--bottom__1"
                          placeholder="Chip-Nr."
                          v-model="secondDogChipId"
                        />
                      </div>
                      <div class="mar--bottom__2"></div>
                      <div class="mar--bottom__2"></div>
                      <div class="mar--bottom__2"></div>
                      <div class="mar--bottom__2"></div>
                      <AccentButton
                        title="Giftköderversicherung aktivieren"
                        filled="true"
                        style="margin-right: 0"
                        @onClick="submit"
                      />
                      <div style="margin-top: 0.5rem" class="mar--bottom__1">
                        Es gelten die
                        <a
                          href="https://doguniversity.de/versicherungsbedingungen-hm"
                          target="_blank"
                          style="font-weight: bold; color: #97bb54"
                          >Versicherungsbedingungen</a
                        >.
                      </div>
                      <AccentButton
                        title="Schadensfall melden"
                        filled="true"
                        light="true"
                        style="margin-bottom: 4rem"
                        @onClick="openLink(selectedCoopPartner.link)"
                      />
                      <div v-if="formSubmitError" style="margin-bottom: 4rem">
                        {{ formSubmitError }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="selectedCoopPartner.discountCoupons"
                    class="coop_card_expanded__footer"
                  >
                    <AccentButton
                      title="1 Monat gratis"
                      filled="true"
                      light="true"
                      @onClick="requestCoupon()"
                    />
                    <AccentButton
                      title="Dr. Sam Rabatt einlösen %"
                      filled="true"
                      @onClick="openLink(selectedCoopPartner.link)"
                    />
                  </div>
                </div>
              </div>

              <div
                v-if="!selectedCoopPartner"
                class="card b-radius--5 overflowHidden width--100"
              >
                <div class="coop_cards_container">
                  <div v-for="partner in coopPartners.data" :key="partner._id">
                    <div
                      class="coop_card"
                      @click="($event) => selectCoopPartner(partner)"
                    >
                      <div
                        class="coop_card__thumbnail"
                        :style="
                          'background-image: url(\'' +
                          doguBackendUrl +
                          'v1/uploads/fetch/' +
                          partner.headerImage.thumbnail +
                          '?authentication=' +
                          doguToken +
                          '\')'
                        "
                      />
                      <div class="coop_card__info">
                        <div class="coop_card__info__logo">
                          <div class="coop_card__logo">
                            <img
                              :src="
                                doguBackendUrl +
                                'v1/uploads/fetch/' +
                                partner.logo.thumbnail +
                                '?authentication=' +
                                doguToken
                              "
                            />
                          </div>
                        </div>
                        <div class="coop_card__info__title">
                          {{ partner.title }}
                        </div>
                        <div class="coop_card__info__subtitle">
                          {{ partner.subtitle }}
                        </div>
                        <p class="coop_card__info__description">
                          {{ partner.shortDescription }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid welcome--ads welcomeAds">
                <div class="flex flex--center" v-for="ad in ads" :key="ad._id">
                  <div
                    class="card b-radius--5 flex font--s16 color--white clickable welcome--ads__item relative overflowHidden flex flex--column"
                    @click="openLink(ad.link)"
                  >
                    <img :src="url + ad.thumbnail.link" class="width--100" />
                    <div
                      class="pad--2 bgColor--white width--100"
                      v-if="ad.description"
                    >
                      <p class="font--s16 color--black">
                        {{ ad.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <transition name="fade" mode="out-in">
      <Modal v-if="showCouponModal" @toggle="showCouponModal = false" title="">
        <div class="coop_coupon_modal">
          <div class="coop_coupon_modal__logo">
            <img
              :src="`${doguBackendUrl}v1/uploads/fetch/${selectedCoopPartner.logo.thumbnail}?authentication=${doguToken}`"
            />
          </div>
          <div v-if="!coupon">Wird geladen...</div>
          <div v-if="coupon" class="coop_coupon_modal__code">
            <input type="text" :value="coupon.code" ref="code" readonly />
            <div class="copy_button" @click="copyCode">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.8 15H15.0002V17.8C15.0002 18.9202 15.0002 19.4802 14.7822 19.908C14.5905 20.2844 14.2841 20.5902 13.9078 20.782C13.48 21 12.9203 21 11.8002 21H6.2002C5.08009 21 4.51962 21 4.0918 20.782C3.71547 20.5902 3.40973 20.2844 3.21799 19.908C3 19.4802 3 18.9202 3 17.8001V12.2001C3 11.08 3 10.5199 3.21799 10.092C3.40973 9.71572 3.71547 9.40973 4.0918 9.21799C4.51962 9 5.08009 9 6.2002 9H9V6.20007C9 5.07997 9 4.51986 9.21799 4.09204C9.40973 3.71572 9.71547 3.40973 10.0918 3.21799C10.5196 3 11.0801 3 12.2002 3H17.8002C18.9203 3 19.48 3 19.9078 3.21799C20.2841 3.40973 20.5905 3.71572 20.7822 4.09204C21.0002 4.51986 21.0002 5.07992 21.0002 6.20002V11.8C21.0002 12.9202 21.0002 13.4802 20.7822 13.908C20.5905 14.2844 20.2841 14.5902 19.9078 14.782C19.48 15 18.9201 15 17.8 15Z"
                  fill="#8BC12E"
                />
                <path
                  d="M15 15H17.8C18.9201 15 19.48 15 19.9078 14.782C20.2841 14.5902 20.5905 14.2844 20.7822 13.908C21.0002 13.4802 21.0002 12.9202 21.0002 11.8V6.20002C21.0002 5.07992 21.0002 4.51986 20.7822 4.09204C20.5905 3.71572 20.2841 3.40973 19.9078 3.21799C19.48 3 18.9203 3 17.8002 3H12.2002C11.0801 3 10.5196 3 10.0918 3.21799C9.71547 3.40973 9.40973 3.71572 9.21799 4.09204C9 4.51986 9 5.07997 9 6.20007V9.00007M3 17.8001V12.2001C3 11.08 3 10.5199 3.21799 10.092C3.40973 9.71572 3.71547 9.40973 4.0918 9.21799C4.51962 9 5.08009 9 6.2002 9H11.8002C12.9203 9 13.48 9 13.9078 9.21799C14.2841 9.40973 14.5905 9.71572 14.7822 10.092C15.0002 10.5199 15.0002 11.0799 15.0002 12.2V17.8C15.0002 18.9202 15.0002 19.4802 14.7822 19.908C14.5905 20.2844 14.2841 20.5902 13.9078 20.782C13.48 21 12.9203 21 11.8002 21H6.2002C5.08009 21 4.51962 21 4.0918 20.782C3.71547 20.5902 3.40973 20.2844 3.21799 19.908C3 19.4802 3 18.9202 3 17.8001Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <AccentButton
            v-if="coupon"
            title="Code kopieren & weiter zum Shop"
            filled="true"
            @onClick="
              () => {
                copyCode().then(() => {
                  openLink(selectedCoopPartner.link);
                });
                //this.showCouponModal = false;
              }
            "
          />
        </div>
      </Modal>
    </transition>
    <transition name="fade">
      <Modal
        title="Herzlichen Glückwunsch"
        v-if="showCertificateModal"
        @toggle="showCertificateModal = !showCertificateModal"
      >
        <div class="flex flex--column width--100 height--100 pad--4">
          <div class="mar--bottom__1 font--s16">
            Gib hier den Namen ein der auf dem Zertifikat erscheinen soll.
            Diesen kannst du nicht mehr ändern.
          </div>
          <label for="certificate_name" class="form--label"
            >Kursabsolvent</label
          >
          <input
            maxlength="60"
            id="certificate_name"
            type="text"
            class="form--input form--input__text mar--bottom__2"
            v-model="certificateName"
          />
          <label
            for="certificate_dog"
            class="form--label"
            v-if="$route.params.organizationID === '5ebe682fb308810011922d13'"
            >Dein Hund</label
          >
          <input
            maxlength="60"
            id="certificate_dog"
            type="text"
            class="form--input form--input__text mar--bottom__2"
            v-model="additionalName"
            v-if="$route.params.organizationID === '5ebe682fb308810011922d13'"
          />
          <AccentButton
            title="Zertifikat generieren"
            filled="true"
            :loading="this.loading"
            @onClick="generateCertificate"
          />
        </div>
      </Modal>
    </transition>
  </div>
</template>
<script>
import AccentButton from "@/components/reusable/AccentButton";
import ActionButton from "@/components/reusable/ActionButton";
import Modal from "@/components/reusable/Modal";
import LoginScreenDogU from "@/components/views/0_Authorization/LoginScreenDogU";

export default {
  name: "WelcomePage",
  components: { ActionButton, Modal, AccentButton, LoginScreenDogU },
  props: ["progress"],
  data() {
    return {
      showCertificateModal: false,
      certificateName: null,
      additionalName: null,
      expandedChapters: [],
      page: 0,
      selectedCoopPartner: null,
      loading: false,
      showCouponModal: false,
      // form state
      firstName: null,
      lastName: null,
      street: null,
      zip: null,
      city: null,
      country: "Deutschland",
      firstDogName: null,
      firstDogChipId: null,
      secondDogName: null,
      secondDogChipId: null,
      formSubmitError: null,
      formSubmitSuccess: true,
      addSecondDog: false,
      componentKey: 0,
    };
  },
  computed: {
    title() {
      /* eslint-disable */
      if (
        this.course &&
        this.course.course &&
        this.course.course.welcomeTitle
      ) {
        if (!this.user.firstName) return this.course.course.welcomeTitle;
        if (this.course.course.welcomeTitle.includes("{{firstName}}")) {
          let name = this.course.course.welcomeTitle;
          return name.replace("{{firstName}}", this.user.firstName);
        }
      }
      return "Herzlich Willkommen!";
    },
    user() {
      return this.$store.getters.getUser;
    },
    url() {
      return this.$store.getters.getURL;
    },
    doguBackendUrl() {
      return this.$store.getters.getDoguBackendUrl;
    },
    doguToken() {
      return this.$store.getters.getDoguToken;
    },

    coupon() {
      return this.$store.getters.getCoupon;
    },
    course() {
      return (
        this.courses.find(
          (el) => el.course._id === this.$route.params.courseID
        ) || {
          course: {
            title: "",
            chapters: [],
            linkElements: [],
          },
        }
      );
    },
    ads() {
      if (
        this.course.course.linkElements != null &&
        this.course.course.linkElements.length
      ) {
        return this.course.course.linkElements.reverse();
      } else {
        return [];
      }
    },
    hasOverview() {
      return (
        this.course != null &&
        ((this.course.course.welcomeText != null &&
          this.course.course.welcomeText !== "") ||
          (this.course.course.headerImage.link != null &&
            this.course.course.headerImage.link !== "") ||
          this.course.course.linkElements.length !== 0)
      );
    },
    hasCertificate() {
      return (
        this.courseDetails.certificate &&
        this.courseDetails.certificate.link != null
      );
    },
    hasCoop() {
      return this.$route.params.courseID === "64118c72efde0c0015e2cee5";
    },
    coopPartners() {
      console.log(this.$store.getters.getCoopPartners);
      return this.$store.getters.getCoopPartners;
    },
    isHanseMerkur() {
      return this.selectedCoopPartner?._id == "649d4cce625a11eea2008b0b";
    },
    isDrSam() {
      return this.selectedCoopPartner?._id == "649d4cce625a11eea2008b0c";
    },
    courses() {
      return this.$store.getters.getCourses;
    },
    courseDetails() {
      /* eslint-disable */
      console.log(this.$store.getters.getCourseDetails);
      return this.$store.getters.getCourseDetails;
    },
    coopRecord() {
      return this.$store.getters.getCoopRecord;
    },
    doguToken() {
      return this.$store.getters.getDoguToken;
    },
    doguUser() {
      return this.$store.getters.getDoguUser;
    },
    next() {
      let chapter = null;
      let page = null;
      if (this.courseDetails.course) {
        for (let i = 0; i < this.courseDetails.course.length; i++) {
          for (let j = 0; j < this.courseDetails.course[i].pages.length; j++) {
            if (
              !this.courseDetails.course[i].pages[j].done &&
              !chapter &&
              !page &&
              !this.accessible(this.courseDetails.course[i]._id)
            ) {
              chapter = this.courseDetails.course[i]._id;
              page = this.courseDetails.course[i].pages[j]._id;
            }
          }
        }
      }
      return {
        chapter,
        page,
      };
    },
  },
  methods: {
    async requestCoupon() {
      this.showCouponModal = true;
      // wait for 2 seconds
      await this.$store.dispatch("retrieveCoupon", {
        partnerId: this.selectedCoopPartner._id,
      });
    },
    async saveSecondDog() {
      const dogs = [
        // this.coopRecord.data.dogs[0],
        {
          name: this.secondDogName ?? "",
          chipId: this.secondDogChipId ?? "",
        },
      ];

      const data = {
        dogs: dogs,
      };

      await this.$store.dispatch("patchCoopRecord", {
        recordId: this.coopRecord._id,
        data,
      });
    },
    async submit() {
      const form = {
        firstName: this.firstName,
        lastName: this.lastName,
        street: this.street,
        zip: this.zip,
        city: this.city,
        country: this.country,
        firstDogName: this.firstDogName,
        firstDogChipId: this.firstDogChipId,
        secondDogName: this.secondDogName,
        secondDogChipId: this.secondDogChipId,
      };

      this.formSubmitError = null;

      // validate
      if (!form.firstName) {
        this.formSubmitError = "Bitte gib deinen Vornamen an";
        return;
      }
      if (!form.lastName) {
        this.formSubmitError = "Bitte gib deinen Nachnamen an";
        return;
      }
      if (!form.street) {
        this.formSubmitError = "Bitte gib deine Straße an";
        return;
      }
      if (!form.zip) {
        this.formSubmitError = "Bitte gib deine Postleitzahl an";
        return;
      }
      if (!form.city) {
        this.formSubmitError = "Bitte gib deine Stadt an";
        return;
      }
      if (!form.country) {
        this.formSubmitError = "Bitte gib dein Land an";
        return;
      }

      await this.$store.dispatch("createCoopRecord", {
        form,
        partnerId: this.selectedCoopPartner._id,
      });
    },
    async toggleCertificate() {
      await this.$store.dispatch("retrieveOwnUser");
      this.certificateName =
        "" + this.user.firstName + " " + this.user.lastName;
      this.showCertificateModal = !this.showCertificateModal;
    },
    async generateCertificate() {
      if (
        !this.certificateName ||
        (!this.additionalName &&
          this.$route.params.organizationID === "5ebe682fb308810011922d13")
      ) {
        this.$store.commit("addToast", {
          type: "warning",
          title: "Bitte Fülle alle Felder aus",
        });
        return;
      }
      this.loading = true;
      await this.$store
        .dispatch("createCertificate", {
          courseID: this.$route.params.courseID,
          data: {
            fullName: this.certificateName,
            dogName: this.additionalName ? this.additionalName : undefined,
          },
        })
        .catch(() => {});
      this.showCertificateModal = false;
      this.loading = false;
    },
    resume() {
      this.$router.push({
        name: "Page",
        params: {
          chapter: this.next.chapter || this.courseDetails.course[0]._id,
          page: this.next.page || this.courseDetails.course[0].pages[0]._id,
        },
      });
    },
    openLink(url) {
      window.open(url);
    },
    expandChapter(val) {
      if (this.expandedChapters.includes(val)) {
        this.expandedChapters = this.expandedChapters.filter(
          (el) => el !== val
        );
      } else {
        this.expandedChapters.push(val);
      }
    },
    accessible(id) {
      if (!this.courseDetails || this.courseDetails.fullAccess) return false;
      let chapter = this.courseDetails.course.find((el) => el._id === id);
      if (chapter) {
        return (
          chapter.timeToAccess +
            new Date(this.courseDetails.joinDate).getTime() >
          new Date()
        );
      }
      return false;
    },
    clickPage(chapter, page) {
      this.$router.push({ name: "Page", params: { chapter, page } });
    },
    copyLink() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      testingCodeToCopy.select();

      try {
        let successful = document.execCommand("copy");
        if (successful) {
          this.$store.commit("addToast", {
            type: "success",
            title: "Link kopiert",
          });
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      }
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    selectCoopPartner(coopPartner) {
      console.log("SELECTED", coopPartner);
      this.selectedCoopPartner = coopPartner;
    },
    async copyCode() {
      this.$refs.code.select();
      await navigator.clipboard.writeText(this.$refs.code.value);
    },
    onLoginSuccess() {
      if (
        this.doguToken &&
        (this.doguUser.clubAbo !== "none" ||
          this.doguUser.roles?.includes("admin"))
      ) {
        this.$store.dispatch("retrieveCoop");
        this.$store.dispatch("getCoopRecord", {
          partnerId: "649d4cce625a11eea2008b0b",
        });
      }
      this.componentKey = this.componentKey + 1;
    },
  },
  watch: {
    courseDetails(newVal) {
      /* eslint-disable */
      this.expandedChapters = newVal.course.map((el) => el._id);
    },
    progress(newVal) {
      if (newVal < 100 && this.page === 2) {
        this.page = 0;
      }
    },
  },
  async mounted() {
    this.$store.dispatch("retrieveOwnUser");

    console.log(this.doguToken);
    console.log(this.doguUser);
    if (
      this.doguToken &&
      (this.doguUser.clubAbo !== "none" ||
        this.doguUser.roles?.includes("admin"))
    ) {
      this.$store.dispatch("retrieveCoop");
      this.$store.dispatch("getCoopRecord", {
        partnerId: "649d4cce625a11eea2008b0b",
      });
    }

    if (this.courseDetails.course) {
      this.expandedChapters = this.courseDetails.course.map((el) => el._id);
    }
  },
};
</script>

<template>
  <div style="margin-top: 2rem; margin-bottom: 2rem">
    <transition name="fade" mode="out-in">
      <div class="flex flex--center flex--column">
        <!-- <div class="flex flex--column auth--info font--s14 pad--2 bgColor--primary b-radius--5 color--white mar--bottom__2" v-if="theme.info && theme.info !== ''">
                {{ theme.info }}
                <a href="https://old.doguniversity.academy/" target="_blank" v-if="$route.params.organizationID === '5ebe682fb308810011922d13'" >
                  <AccentButton :light="true" title="Zum alten Mitgliederbereich" :full-width="true" class="mar--top__2 noDec" />
                </a>
              </div> -->
        <div
          class="auth--container pad--25"
          :key="'login'"
          v-if="!passcodeRequired"
        >
          <div class="flex flex--center width--100">
            <img
              class="width--max__100pc height--max__20rem clickable mar--bottom__2"
              :src="`${url}${theme.bannerLogo.link}`"
              v-if="theme.bannerLogo.link"
            />
          </div>
          <label class="form--label" for="login_email"> E-Mail </label>
          <input
            class="form--input__text mar--bottom__1"
            :class="{ 'form--input__warning': markEmail }"
            id="login_email"
            type="text"
            v-model="loginEmail"
          />
          <label class="form--label" for="login_password"> Passwort </label>
          <input
            @keyup.enter="login"
            class="form--input__text mar--bottom__2"
            id="login_password"
            type="password"
            v-model="loginPassword"
          />
          <div class="flex flex--center__vert width--100 mar--bottom__2">
            <div
              class="auth--stay b-radius--5 mar--right__1 clickable flex flex--center"
              @click="stayLogged = !stayLogged"
              :class="{
                'border--dark': stayLogged,
                'border--light': !stayLogged,
              }"
            >
              <transition name="fade">
                <i class="ion-md-checkmark font--s2" v-if="stayLogged" />
              </transition>
            </div>
            <div class="font--s16 clickable" @click="stayLogged = !stayLogged">
              Angemeldet bleiben
            </div>
          </div>
          <div class="mar--bottom__1">
            <AccentButton
              :filled="true"
              :full-width="true"
              @keyup.enter="login"
              @onClick="login"
              title="Anmelden"
            />
          </div>
          <div class="font--s14 font--center clickable" @click="forgotPassword">
            Passwort vergessen?
          </div>
        </div>
        <div
          class="auth--container pad--25 flex flex--center"
          :key="'passcode'"
          v-if="passcodeRequired"
        >
          <PasscodeDogU
            :login-email="loginEmail"
            :login-password="loginPassword"
            :stay-logged="stayLogged"
            :onSuccess="onSuccess"
          />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import AccentButton from "@/components/reusable/AccentButton";
import PasscodeDogU from "@/components/views/0_Authorization/PasscodeDogU";

export default {
  name: "LoginScreen",
  components: { PasscodeDogU, AccentButton },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loginEmail: null,
      loginPassword: null,
      stayLogged: false,
      passcodeRequired: false,
      markEmail: false,
    };
  },
  computed: {
    theme() {
      return this.$store.getters.getTheme;
    },
    url() {
      return this.$store.getters.getURL;
    },
  },
  methods: {
    async login() {
      this.loading = true;
      this.$store
        .dispatch("loginDogu", {
          credentials: {
            email: this.loginEmail,
            password: this.loginPassword,
            rememberLogin: this.stayLogged,
          },
        })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            this.onSuccess();
          } else if (res.status === 401 && res.data.data.id === 5) {
            this.passcodeRequired = true;
          }
        });
    },
    forgotPassword() {
      if (this.loginEmail && this.loginEmail !== "") {
        this.$store
          .dispatch("forgotPassword", {
            organizationID: this.$route.params.organizationID,
            email: this.loginEmail,
          })
          .then(() => {
            this.markEmail = false;
            this.$store.commit("addToast", {
              type: "success",
              title: "Bitte schau in deine Mails",
            });
          });
      } else {
        this.markEmail = true;
        this.$store.commit("addToast", {
          type: "warning",
          title: "Bitte gib die E-Mail ein mit der du angemeldet bist.",
        });
      }
    },
  },
};
</script>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import ClickOutside from "vue-click-outside";
import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import VueAnalytics from "vue-analytics";
// import VueMatomo from 'vue-matomo'

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.config.productionTip = false;

Vue.directive("ClickOutside", ClickOutside);

Vue.component("loader", PulseLoader);
Vue.use(VueVideoPlayer);
// Vue.use(VueMatomo, {
//   router: router,
//   host: 'https://analytics.neon.dev/',
//   siteId: 7,
// });
Vue.use(VueAnalytics, {
  id: "UA-184396070-3",
  router,
});

Sentry.init({
  Vue,
  dsn: "https://35336b2140c04f90914162b1d02fd154@o440203.ingest.sentry.io/6653070",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "doguniversity.academy", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");

/* eslint-disable */

import CourseLoader from "@/components/views/2_Course/CourseLoader";
import CoursesLoader from "@/components/views/1_Overview/CoursesLoader";
import LoginScreen from "@/components/views/0_Authorization/LoginScreen";
import WelcomePage from "@/components/views/2_Course/WelcomePage";
import CourseContent from "@/components/views/2_Course/CourseContent";
import ForgotPassword from "@/components/views/0_Authorization/ForgotPassword";
import SettingsLoader from "@/components/views/3_Settings/SettingsLoader";
import SubscriptionSettingsLoader from "@/components/views/3_Settings/SubscriptionSettingsLoader";
import CoursesOverview from "@/components/views/1_Overview/CoursesOverview";
import MainLoader from "@/components/views/MainLoader";
import ChapterOverview from "@/components/views/2_Course/ChapterOverview";
import DataProtection from "@/components/views/4_About/DataProtection";
import AGB from "@/components/views/4_About/AGB";
import Imprint from "@/components/views/4_About/Imprint";
import Fallback from "@/components/views/0_Authorization/Fallback";
import OrgLoader from "@/components/views/OrgLoader";

export default [
  {
    path: "/",
    component: OrgLoader,
  },
  {
    path: "/organizations/:organizationID/passwords/reset/:sessionToken",
    component: ForgotPassword,
  },
  {
    path: "/fallback",
    component: Fallback,
  },
  {
    path: "/login",
    redirect: "/",
  },
  {
    path: "/:organizationID",
    component: MainLoader,
    children: [
      {
        path: "login",
        component: LoginScreen,
      },
      {
        path: "",
        component: CoursesLoader,
        children: [
          {
            path: "",
            name: "CourseOverview",
            component: CoursesOverview,
          },
          {
            path: "settings",
            name: "UserSettings",
            component: SettingsLoader,
          },
          {
            path: "settings/subscription",
            name: "UserSubscriptionSettings",
            component: SubscriptionSettingsLoader,
          },
          {
            path: "data",
            component: DataProtection,
          },
          {
            path: "agb",
            component: AGB,
          },
          {
            path: "imprint",
            component: Imprint,
          },
        ],
      },
      {
        path: "Course/:courseID",
        component: CourseLoader,
        children: [
          {
            path: "",
            name: "CourseWelcome",
            component: WelcomePage,
          },
          {
            path: ":chapter",
            name: "ChapterOverview",
            component: ChapterOverview,
          },
          {
            path: ":chapter/:page",
            name: "Page",
            component: CourseContent,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    redirect: "/fallback",
  },
];

import { OAMN } from "@/consts";

export function extendTheme(organizationId, theme) {
  if (organizationId === OAMN) return extendOamnTheme(theme);
  return theme;
}

function extendOamnTheme(theme) {
  return {
    ...theme,
    _extra_value: "value",
  };
}

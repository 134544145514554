<template>
  <div
    class="fixed navBar flex flex--column overflowAuto"
    :class="{ 'navBar--out': mobileOut }"
  >
    <div class="navBar--header flex flex--column pad--2">
      <div class="flex font--s16 font--w700 clickable" @click="back">
        <i class="ion-ios-arrow-back mar--right__1" />
        <div class="" v-if="!$route.params.chapter">Alle Kurse</div>
        <div class="" v-if="$route.params.chapter">Zur Übersicht</div>
      </div>
      <div
        class="font--s25 font--w700 clickable lineHeight--12"
        @click="clickTitle"
      >
        {{ course.course.title }}
      </div>
    </div>
    <div class="pad--2 border--bottom__light flex flex--center__vert">
      <div
        class="navBar--progress bgColor--grey b-radius--5 mar--right__1 flex"
      >
        <div
          class="height--100 bgColor--primary b-radius--5 transition"
          :style="{ width: `${progress}%` }"
        />
      </div>
      {{ progress }}%
    </div>
    <div class="navBar--outline flex--grow">
      <div
        class="navBar--chapter__wrapper"
        v-for="chapter in courseDetails.course"
        :key="chapter._id"
      >
        <div
          class="navBar--chapter clickable pad--2"
          @click.self="expandChapter(chapter._id)"
        >
          <div
            class="flex flex--space__hor font--s16 font--w700 lineHeight--1"
            @click.self="expandChapter(chapter._id)"
          >
            <div class="flex flex--center__vert">
              <div
                class="mar--right__1"
                @click="clickChapterTitle(chapter._id)"
              >
                {{ chapter.title }}
              </div>
              <div
                class="flex flex--center__vert b-radius--5 bgColor--primary pad--05 flex--grow width--min__70px"
                v-if="accessible(chapter._id)"
              >
                <i class="color--white ion-md-unlock mar--right__1 font--s14" />
                <div class="color--white font--s1 font--w500">
                  {{
                    `${
                      timeUntilUnlock(chapter._id) > 1
                        ? timeUntilUnlock(chapter._id)
                        : "&lt; 1"
                    } Tag${timeUntilUnlock(chapter._id) > 1 ? "e" : ""}`
                  }}
                </div>
              </div>
            </div>
            <i
              class="ion-ios-arrow-forward navBar--chapter__expand flex flex--center"
              :class="{ 'rotate--90': !expandedChapters.includes(chapter._id) }"
              @click.self="expandChapter(chapter._id)"
            />
          </div>
        </div>
        <div
          class="navBar--page font--s14 flex--center__vert flex--space__hor pad--2 clickable lineHeight--14"
          v-for="page in chapter.pages"
          :key="page._id"
          @click.self="
            accessible(chapter._id) ? null : clickPage(chapter._id, page._id)
          "
          :class="{
            hide: !expandedChapters.includes(chapter._id),
            flex: expandedChapters.includes(chapter._id),
            'bgColor--greyLight': $route.params.page === page._id,
          }"
        >
          <div
            class="flex"
            :class="{
              'color--primary': $route.params.page === page._id,
              'font--w700': $route.params.page === page._id,
            }"
          >
            <div
              class="navBar--page__progress flex flex--center clickable mar--right__1"
              :class="{ 'navBar--page__done': page.done }"
              @click="
                accessible(chapter._id) ? null : toggleDone(chapter._id, page)
              "
            >
              <i
                class="ion-md-checkmark"
                v-if="page.done && !accessible(chapter._id)"
              />
              <i class="ion-md-lock" v-if="accessible(chapter._id)" />
            </div>
            <div
              @click.self="
                accessible(chapter._id)
                  ? null
                  : clickPage(chapter._id, page._id)
              "
            >
              {{ page.title }}
            </div>
          </div>
          <div class="font--s1 navBar--page__duration flex flex--end__hor">
            {{ page.duration ? `${page.duration} min` : "" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CourseNavigation",
  props: ["mobileOut", "progress"],
  data() {
    return {
      expandedChapters: [],
    };
  },
  computed: {
    course() {
      return (
        this.courses.find(
          (el) => el.course._id === this.$route.params.courseID
        ) || {
          course: {
            title: "",
            chapters: [],
          },
        }
      );
    },
    courses() {
      return this.$store.getters.getCourses;
    },
    courseDetails() {
      return this.$store.getters.getCourseDetails;
    },
  },
  methods: {
    accessible(id) {
      if (!this.courseDetails || this.courseDetails.fullAccess) return false;
      let chapter = this.courseDetails.course.find((el) => el._id === id);
      if (chapter) {
        return (
          chapter.timeToAccess +
            new Date(this.courseDetails.joinDate).getTime() >
          new Date()
        );
      }
      return false;
    },
    timeUntilUnlock(id) {
      let chapter = this.courseDetails.course.find((el) => el._id === id);
      if (chapter && chapter.timeToAccess != null) {
        if (
          new Date(this.courseDetails.joinDate).getTime() < new Date().getTime()
        ) {
          return Math.round(
            (chapter.timeToAccess +
              new Date(this.courseDetails.joinDate).getTime() -
              new Date().getTime()) /
              86400000
          );
        } else {
          if (chapter.timeToAccess === 0 || chapter.timeToAccess == null) {
            return Math.round(
              (new Date(this.courseDetails.joinDate).getTime() -
                new Date().getTime()) /
                86400000
            );
          } else {
            return Math.round(
              (chapter.timeToAccess +
                new Date(this.courseDetails.joinDate).getTime() -
                new Date().getTime()) /
                86400000
            );
          }
          // return Math.round(((chapter.timeToAccess + new Date().getTime()) - (new Date(this.course.joinDate).getTime())) / 86400000)
        }
      }
    },
    clickPage(chapter, page) {
      this.$emit("close");
      this.$router.push({ name: "Page", params: { chapter, page } });
    },
    clickTitle() {
      this.$emit("close");
      this.$router.push({ name: "CourseWelcome" });
    },
    clickChapterTitle(id) {
      this.$emit("close");
      this.$router.push({ name: "ChapterOverview", params: { chapter: id } });
    },
    back() {
      if (this.$route.params.chapter) {
        this.$emit("close");
        this.$router.push({ name: "CourseWelcome" });
      } else {
        const organizationID = this.$route.params.organizationID;
        this.$router.push({
          name: "CourseOverview",
          params: { organizationID },
        });
      }
    },
    expandChapter(val) {
      if (this.expandedChapters.includes(val)) {
        this.expandedChapters = this.expandedChapters.filter(
          (el) => el !== val
        );
      } else {
        this.expandedChapters.push(val);
      }
    },
    toggleDone(chapterID, page) {
      this.$store.dispatch("setPageProgress", {
        courseID: this.$route.params.courseID,
        chapterID,
        pageID: page._id,
        status: {
          done: !page.done,
        },
      });

      this.$store.commit("setPageProgress", {
        chapterID,
        pageID: page._id,
        status: {
          done: !page.done,
        },
      });
    },
  },
  watch: {
    // courseDetails(newVal) {
    //     this.expandedChapters = newVal.course.map(el => el._id);
    // },
  },
  mounted() {
    if (this.courseDetails.course) {
      this.expandedChapters = this.courseDetails.course.map((el) => el._id);
    }
  },
};
</script>
